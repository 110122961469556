// src/pages/Shop.js
import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CatalogSection from '../components/CatalogSection/CatalogSection';

const ShopPage = ({ data }) => {
  return (
    <Layout>
      <CatalogSection
        title="SHOP"
        subTitle="SHOP"
        services={[
          {
            title: 'リヴェラール楽天市場店',
            href: 'https://www.rakuten.co.jp/riverall/',
            imageSrc: '/t101.png',
          },
          {
            title: 'Fascino楽天市場店',
            href: 'https://www.rakuten.co.jp/fascino/',
            imageSrc: '/t102.png',
          },
          {
            title: 'marcadimoda楽天市場店',
            href: 'https://www.rakuten.co.jp/marcadimoda/',
            imageSrc: '/t103.png',
          },
          {
            title: 'リヴェラールyahooショッピング店',
            href: 'https://shopping.geocities.jp/riverall/',
            imageSrc: '/t104.png',
          },
          {
            title: 'Fascinoyahooショッピング店',
            href: 'https://shopping.geocities.jp/riverall-men/',
            imageSrc: '/t105.png',
          },
          {
            title: '【Amazon】リヴェラール',
            href: 'https://www.amazon.co.jp/s?me=A3154ROTTLSWKG&marketplaceID=A1VC38T7YXB528',
            imageSrc: '/t106.png',
          },
          {
            title: '【au PAY マーケット】リヴェラール',
            href: 'https://plus.wowma.jp/user/11386081/plus/index.html',
            imageSrc: '/t107.png',
          },
          {
            title: '【Qoo10】リヴェラール',
            href: 'https://www.qoo10.jp/shop/riverall?__ar=Y',
            imageSrc: '/t108.png',
          },
          {
            title: '【BUYMA】リヴェラール',
            href: 'https://www.buyma.com/buyer/4108056.html',
            imageSrc: '/t109.png',
          },
          {
            title: '【MEGASEEK】リヴェラール',
            href: 'https://www.magaseek.com/shop/top/sh_RIVERA-tr_s',
            imageSrc: '/t110.png',
          },
          {
            title: '【dfashion】リヴェラール',
            href: 'https://dfashion.docomo.ne.jp/shop/top/pt_0-tr_s-sh_RIVERA',
            imageSrc: '/t111.png',
          },
          {
            title: '【SHOPLIST】リヴェラール',
            href: 'https://shop-list.com/women/brand/riverall/',
            imageSrc: '/t112.png',
          },
          {
            title: '【LOCONDO】リヴェラール',
            href: 'https://www.locondo.jp/shop/in-shop/SSST1334D/',
            imageSrc: '/t113.png',
          },
        ]}
      />
    </Layout>
  );
};

export default ShopPage;

export const Head = () => (
  <Seo
    title="404 Not Found"
    description="お探しのページが見つかりませんでした。"
  />
);
